// 控制分享按钮
const shareList = [
  'menuItem:share:appMessage',
  'menuItem:share:timeline',
  'menuItem:share:qq',
  'menuItem:share:weiboApp',
  'menuItem:favorite',
  'menuItem:share:facebook',
  'menuItem:share:QZone'
]

// 控制复制按钮
const shareCopyList = [
  'menuItem:copyUrl'
]

// 个微分享时需要注册的方法
const wxShareConfig = [
  'agentConfig',
  'checkJsApi',
  'updateTimelineShareData', // 1.4.0新版本的分享朋友圈接口，注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
  'updateAppMessageShareData', // 1.4.0新版本的分享给朋友接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
  'onMenuShareAppMessage', // 老版本分享朋友接口。
  'onMenuShareTimeline', // 老版本分享朋友圈接口。
  'showMenuItems',
  'hideMenuItems',
]

// 企微分享时需要注册的方法
const qwShareConfig = [
  'agentConfig',
  'onMenuShareAppMessage', // 获取“转发”按钮点击状态及自定义分享内容接口
  'onMenuShareWechat', // 获取“微信”按钮点击状态及自定义分享内容接口
  'onMenuShareTimeline', // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
  'openDefaultBrowser', // 使用浏览器打开网页
  'hideOptionMenu', // 隐藏右上角菜单接口
  'showOptionMenu', // 显示右上角菜单接口
]

const qwRegisterJsApiList = [
  'previewFile',
  'openEnterpriseChat',
  'getCurExternalChat',
  'onHistoryBack',
  'agentConfig',
  'openDefaultBrowser',
  'setClipboardData',
  'hideMenuItems',
  'showMenuItems',
  'onMenuShareAppMessage',
  'hideOptionMenu',
  'showOptionMenu',
  'openAddress',
  'launchMiniprogram',
  'getLocation',
  'navigateToAddCustomer',
  'chooseImage',
  'shareToExternalContact',
  'openExistedChatWithMsg',
  'shareToExternalMoments',
]

const qwVerifyJsApiList = [
  'selectExternalContact',
  'sendChatMessage',
  'getCurExternalContact',
  'getCurExternalChat',
  'onMenuShareAppMessage',
  'hideMenuItems',
  'showMenuItems',
  'hideOptionMenu',
  'showOptionMenu',
  'setClipboardData',
  'openAddress',
  'shareToExternalContact',
  'launchMiniprogram',
  'getLocation',
  'navigateToAddCustomer', // 进入添加客户界面
  'chooseImage',
  'openExistedChatWithMsg', // 打开已有群聊
  'shareToExternalMoments', // 发表内容到客户朋友圈
]

export {
  shareList,
  shareCopyList,
  wxShareConfig,
  qwShareConfig,
  qwRegisterJsApiList,
  qwVerifyJsApiList,
}
