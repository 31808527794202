import { $jsdkHttp, getReqUrl } from './jssdk-http'

function isBrowser() {
  return typeof window !== 'undefined'
}

// 判断是否为微信环境(企微/个微)
const isMicromessenger = isBrowser() && /micromessenger/i.test(window.navigator.userAgent)
// 判断是否为企微环境
const isWxwork = isBrowser() && /wxwork/i.test(window.navigator.userAgent)
// 判断是否执行企微模式：如果不是微信环境，则均执行企微模式
const isQw = isWxwork || !isMicromessenger
// 判断是否为个微环境
const isWx = !isQw
// 是否为Ios设备访问
const isIOS = isBrowser() && /(iPhone|iPod|iPad|ios)/i.test(window.navigator.userAgent)

// 获取URL携带的参数
function fquery () {
  var query = window.location.hash.split('?')[1] || window.location.search.substr(1);
  var reg = /([^=&\s]+)[=\s]*([^&\s]*)/g;
  var obj: any = {};
  while (reg.exec(query)) obj[RegExp.$1] = RegExp.$2;
  window.urlQueryObj = obj;
  return obj;
};

// 获取id
const getIdmapping = async (params: {
  corpid: string,
  ids: string[],
  id_type: string,
  to_open: boolean,
}, idmapping = false, contact = true) => {
  let ids = params.ids
  if (idmapping && ids?.length) {
    try {
      const res: any = await $jsdkHttp({
        method: 'post',
        url: getReqUrl('idmapping'),
        params
      });
      if (res.success) {
        ids = res.data
      }
      console.log('res: idmapping', res);
    } catch (error) {
      console.log('error: ', error);
    }
  }
  return contact ? ids.join(';') : ids
}

// 获取请求参数
const getParams = () => {
  var urlQueryObj = fquery();
  var jsdkLocalConfig = JSON.parse(window.localStorage.getItem('config') || '{}');
  var jsdkParams = {
      corpId: urlQueryObj.corpId || urlQueryObj.corpid || jsdkLocalConfig.corpId || jsdkLocalConfig.corpid,
      agentId: urlQueryObj.agentId || urlQueryObj.agentid || jsdkLocalConfig.agentId || jsdkLocalConfig.agentid,
      account: urlQueryObj.account || jsdkLocalConfig.account,
      isSideMenu: urlQueryObj.isSideMenu || jsdkLocalConfig.isSideMenu,
      appId: urlQueryObj.appId || jsdkLocalConfig.appId
  };

  if (urlQueryObj.account || urlQueryObj.corpId || urlQueryObj.corpid || urlQueryObj.agentId || urlQueryObj.agentid) {
    window.localStorage.setItem('config', JSON.stringify(jsdkParams));
  }

  return jsdkParams
}

// 登录
const jsdkAutoLoginFunc = async (cb: any) => {
  var urlQueryObj = fquery();
  let res: any = null
  if (urlQueryObj.sessionId) {
    const params = {
      sessionId: urlQueryObj.sessionId,
      type: urlQueryObj.isSideMenu ? 'cbl' : 'qw'
    }

    try {
      res = await $jsdkHttp({
        url: getReqUrl('login'),
        method: 'get',
        params
      })

      if (res.success) {
        window.localStorage.setItem('user', JSON.stringify(res.data));
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  if (typeof cb === 'function') {
    cb(res)
  }
  return res
};

export {
  isMicromessenger,
  isWxwork,
  isQw,
  isWx,
  isIOS,
  fquery,
  getParams,
  getIdmapping,
  jsdkAutoLoginFunc,
}
