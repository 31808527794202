// 给script添加类型
interface SCRIPTTYPE {
  readyState?: string,
  onreadystatechange?: any
}

export default (sdkUrl = '//res.wx.qq.com/open/js/jweixin-1.2.0.js') => new Promise((resolve, reject) => {
  console.log('进入【设置问下分享skd url函数】');
  if (window.wx && window.wx.ready) {
    console.log('window.wx && window.wx.ready已存在，跳过手动设置微信sdk url');
    resolve(true);
  } else if (sdkUrl) {
    console.log('手动设置微信sdk url-【开始】');

    // 判断是否为新版本的sdk
    const version = sdkUrl.split('jweixin-')[1];
    console.log(`当前sdk版本为：${version}`);

    // url协议
    if (!sdkUrl.match('http')) sdkUrl = window.location.protocol + sdkUrl;
    const script = <HTMLScriptElement & SCRIPTTYPE>document.createElement('script');

    if (script.readyState) {
      // IE
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded'
              || script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          if (window.wx && window.wx.ready) {
            console.log('手动设置微信sdk url-【成功】');
            resolve(true);
          } else {
            const err = new Error(
              '微信jssdk引入失败，可能是sdk url错误，请正确引入！官方文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3',
            );
            reject(err);
          }
        }
      };
    } else {
      // 其他浏览器
      script.onload = () => {
        if (window.wx && window.wx.ready) {
          console.log('手动设置微信sdk url-【成功】');
          resolve(true);
        } else {
          const err = new Error(
            '微信jssdk引入失败，可能是sdk url错误，请正确引入！官方文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3',
          );
          reject(err);
        }
      };
    }

    script.setAttribute('src', sdkUrl);
    script.setAttribute('data-namespace', `${sdkUrl}-${new Date().getTime()}`);
    if (document.body) {
      document.body.appendChild(script);
    } else {
      document.head.appendChild(script);
    }
  } else {
    const err = new Error('微信sdk url为空，手动设置微信sdk url-【失败】');
    reject(err);
  }
});
