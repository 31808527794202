// 获取接口请求URL
const apiBaseUrl = typeof window !== 'undefined' ? (window.envConfig && window.envConfig.VUE_APP_PC_B_BASE_URL) || '' : ''

const getReqUrl = (key: any) => {
  // 接口请求地址
  const apiUrl: any = {
    qw: '/wxwork/v1/js_sdk_sign/both',
    wx: '/wxopen/v1/wxpublic/jsSDKSign',
    idmapping: '/wxwork/v1/id/query/batch/idmapping/v1',
    login: '/biz/v1/qw_account/session/get_login_session'
  }
  return `${apiBaseUrl}${apiUrl[key]}`
}

// 请求接口方法
var $jsdkHttp = function (options: {
  method: 'get' | 'post',
  url: string,
  params: any
}) {
  let url = options.url
  let paramsStr = ''
  Object.entries(options.params).forEach(arr => {
    if (arr[0]) {
      paramsStr += `${paramsStr ? '&' : ''}${arr[0]}=${arr[1] || ''}`
    }
  })
  if (options.method === 'get') {
    url += `?${paramsStr}`
    paramsStr = ''
  }
  var jsdkxhr = new XMLHttpRequest();
  jsdkxhr.open(options.method, url, true);
  if (options.method === 'post') {
    // 设置header
    jsdkxhr.setRequestHeader('Content-type', 'application/json; charset=utf-8')
    paramsStr = JSON.stringify(options.params)
  }
  jsdkxhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  jsdkxhr.setRequestHeader('Accept', '*/*');
  jsdkxhr.send(paramsStr);
  return new Promise(function (resolve, reject) {
    jsdkxhr.onload = function (res) {
      if (Math.floor(jsdkxhr.status / 100) === 2) {
          resolve(JSON.parse(jsdkxhr.response) || {});
      }
      resolve(res);
    };

    jsdkxhr.onerror = function (res) {
      reject(res);
    };
  });
};

export {
  getReqUrl,
  $jsdkHttp
}

