import { $jsdkHttp, getReqUrl } from './utils/jssdk-http'

import setLoadWxSdk from './utils/setLoadWxSdk';

import {
  isMicromessenger,
  isWxwork,
  isQw,
  isWx,
  isIOS,
  // fquery,
  getParams,
  getIdmapping,
  jsdkAutoLoginFunc,
} from './utils/common'

import {
  shareList,
  shareCopyList,
  wxShareConfig,
  qwShareConfig,
  qwRegisterJsApiList,
  qwVerifyJsApiList,
} from './utils/const-val'

// 获取initSDKType
import type { ConfigType, ShareDataType, WxConfigItem, WxConfigType } from './model/initSDKType';

class CfxJsSdkInit {
  static readonly install: CfxJsSdkInit;

  static readonly isNeedConfig = true;

  // 必须使用getInstance方法创建对象
  static getInstance(config: ConfigType) {
    return new CfxJsSdkInit(config);
  };

  private sdkPendingList: any[] = [];

  baseConfig: ConfigType = {
    account: '',
    companyId: '',
    initType: 'wx',
    autoGetCurExternal: false,
    debug: false,
    isUseEncode: false,
  };

  private idmapping = false;

  constructor(config: ConfigType) {
    this.baseConfig = {
      ...this.baseConfig,
      ...config,
    };
  };

  /**
   *
   * @param {*配置信息} config
   * @description 通过接口，获取微信sdk签名配置
   */
  private getJsSdkSignConfig = async (prams: {readonly signUrl?: string, readonly ticketType?:string}) => {
    let { signUrl, ticketType } = prams
    const { corpId, agentId, account= '', appId, mode, message, companyId = '' } = this.baseConfig;

    // 如果没有传入signUrl，
    if (!signUrl) {
      // 获取session中registerWxSdkUrl，适配history模式下，ios分享bug
      // registerWxSdkUrl值：在页面授权完成后保存（安卓不用）
      signUrl = window.sessionStorage.getItem('registerWxSdkUrl') || '';
      // 如果当前机型不是ios或者signUrl没值
      if (!isIOS || !signUrl || mode === 'hash') {
        const { location: { href } } = window;
        [signUrl] = href.split('#');
      }
    }

    signUrl = encodeURIComponent(signUrl);

    let res: any = null;

    const url = getReqUrl(isQw || ticketType ? 'qw' : 'wx')

    const wxConfig = {
      agent: undefined,
      corp: undefined
    }

    const accId = account || companyId

    if (isQw || ticketType) {
      res = await $jsdkHttp({
        method: 'get',
        url,
        params: {
          signUrl,
          agentId: agentId || '',
          corpId: corpId || '',
          ticketType: ticketType || '',
          account: accId,
          companyId: accId
        }
      });
      this.idmapping = res.data.idmapping
      this.baseConfig.opencorpid = res.data.opencorpid
      wxConfig.agent = { ...res.data.agent, appId: appId || this.baseConfig.corpId }
      wxConfig.corp = { ...res.data.corp, appId: appId || this.baseConfig.corpId }
    } else {
      res = await $jsdkHttp({
        method: 'get',
        url,
        params: {
          signUrl,
          companyId: account || '',
          appId: appId || '',
        }
      });
      wxConfig.corp = res.data
    }

    if (res && res.success) {
      return wxConfig
    }
    message && message('获取签名失败');
    console.log('【获取微信jssdk参数的】接口调用失败: ');
    return
  };

  // 验证wx.ready
  getReadyCallBack() {
    const { wx } = window;
    const { message } = this.baseConfig;
    return new Promise((resolve, reject) => {
      // 页面加载完成后用户就有可能调用微信的分享，所以当页面ready 完后就加载
      wx.ready(async () => {
        resolve(true);
      });

      // 微信预加载失败回调
      wx.error((res:any) => {
        message && message(`${res.errMsg}---微信sdk注册失败`);
        // 当使用微信开发者工具调试本地代码时，可以不抛出异常来阻断后续的逻辑执行，因为此处失败的情况下，微信开发者工具内部还是可以正常调用api的
        if(window.location.href.startsWith('http:')){
          resolve(true);
        }else {
          reject(`${res.errMsg}---微信sdk注册失败`)
        }
      });
    });
  }

  // 处理agentConfig
  async setAgentConfig(params: { readonly jsApiList?: readonly string[], readonly wxConfig?: WxConfigItem }) {
    const idmapping = this.idmapping
    const { wx } = window;
    const { corpId, opencorpid, agentId, autoGetCurExternal, message } = this.baseConfig;
    let wxConfig = params.wxConfig
    if (isMicromessenger) {
      // 只有微信环境才执行
      await this.getReadyCallBack()
    }
    if (!wxConfig) {
      // 如果入参中wxConfig无值，需要请求接口获取
      const res = await this.getJsSdkSignConfig({
        ticketType: 'agent_config'
      });
      wxConfig = res?.agent
    }
    const config = {
      corpid: opencorpid || corpId,
      agentid: agentId,
      ...wxConfig,
      jsApiList: params.jsApiList || [],
    };
    if (wx.agentConfig) {
      return new Promise((resolve, reject) => {
        wx.agentConfig({
          ...config,
          success(res: any) {
            console.log('res: ', res);
            window.wxScrmJsdk = 'success';
            if(isWxwork && autoGetCurExternal){
              // 这一块后期会删除，暂时保留
              wx.invoke('getCurExternalContact', {}, async (wxres: any) => {
                if (wxres.err_msg === 'getCurExternalContact:ok') {
                  window.wxExternalUserid = await getIdmapping({
                    corpid: corpId || '',
                    ids: wxres.userId ? [wxres.userId] : [],
                    id_type: 'external',
                    to_open: false
                  }, idmapping) as string;
                  // 通知客服页面 userId 已存在
                  window.wxKfInfo && (window.wxKfInfo.hasWxUserId = true);
                }
              });
              wx.invoke('getCurExternalChat', {}, function (wxres: any) {
                if (wxres.err_msg === 'getCurExternalChat:ok') {
                  window.wxExternalChatid = wxres.chatId || '';
                }
              });
            }
            // 回调
            resolve(true);
          },
          fail(res: any) {
            console.log('res: ', res);
            window.wxScrmJsdk = 'jsdk-fail';
            if (res.errMsg.indexOf('function not exist') > -1) {
              console.log('版本过低请升级');
            }
            message && message(`${res.errMsg}---wx.agentConfig`);
            reject(`${res.errMsg}---wx.agentConfig`)
          },
        });
      });
    } else {
      window.wxScrmJsdk = 'agent-fail';
      return Promise.reject('未找到wx.agentConfig方法')
    }
  }

  /**
   * 微信分享config
   */
  setWxShareConfig (wxConfig:WxConfigType) {
    const { wx } = window;
    const { debug, registerJsApiList, openTagList } = this.baseConfig;
    const jsApiList = Array.from(new Set([
      ...wxShareConfig,
      ...(registerJsApiList || []),
      ...(openTagList || [])
    ]))
    wx.config({
      // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      debug,
      // 必填，需要使用的JS接口列表
      jsApiList,
      // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      openTagList: openTagList || [],
      ...wxConfig.corp,
    });

    // 必须放在 回调函数里，不然当在注册的时候 ，有可能还没有返回数据回来，例如刷新问题
    // 微信检查接口列表
    wx.checkJsApi({
      // 需要检测的JS接口列表
      jsApiList,
      // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      openTagList: openTagList || [],
      success: function(res: any) {
        console.log('wx.checkJsApi:success ', res);
        // 以键值对的形式返回，可用的api值true，不可用为false
      }
    });

    return this.setAgentConfig({ wxConfig: wxConfig.agent })
  };

  /**
   * 企微分享config 
   */
  setQwWxShareConfig (wxConfig:WxConfigType) {
    const { wx } = window;
    const {
      debug, registerJsApiList
    } = this.baseConfig;
    const jsApiList = Array.from(new Set([
      ...qwShareConfig,
      ...(registerJsApiList || []),
    ]))

    wx.config({
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      debug,

      // 必填，需要使用的JS接口列表
      jsApiList,

      ...wxConfig.corp,
    });

    // 必须放在 回调函数里，不然当在注册的时候 ，有可能还没有返回数据回来，例如刷新问题
    // 微信检查接口列表
    wx.checkJsApi({
      // 需要检测的JS接口列表
      jsApiList,
      success: function(res: any) {
        console.log('wx.checkJsApi:success ', res);
        // 以键值对的形式返回，可用的api值true，不可用为false
      }
    });

    return this.setAgentConfig({ wxConfig: wxConfig.agent, jsApiList })
  };

  /**
   * 设置个微分享配置
   */
  async setWxShare(
    shareData: ShareDataType,
    callBack: (success: boolean, type: string) => void,
  ) {
    const { wx } = window;
    const { sdkUrl } = this.baseConfig;
    await this.getReadyCallBack()

    // 判断是否为新版本的sdk
    const version = sdkUrl?.split('jweixin-')[1] || [];
    const isNewVersionSdk = Number(version[0]) >= 2 || Number(version[2]) >= 4;
    if (isNewVersionSdk) {
      // ===========【新版本】===========
      // 分享给好友
      wx.updateAppMessageShareData({
        ...shareData,
        success() {
          // 【设置】：分享好友成功回调
          // '新版本：【设置】分享给好友【成功】';
          callBack(true, 'friend');
        },
        cancel() {
          // '新版本：【设置】分享给好友【失败】';
          callBack(false, 'friend');
        },
      });

      // 分享到朋友圈
      wx.updateTimelineShareData({
        ...shareData,
        success() {
          // 【设置】：分享给朋友圈成功回调
          // '新版本：【设置】分享到朋友圈【成功】';
          // 调用传进来的回调函数
          callBack(true, 'friendCircle');
        },
        cancel() {
          // '新版本：【设置】分享到朋友圈【失败】';

          // 调用传进来的回调函数
          callBack(false, 'friendCircle');
        },
      });
    } else {
      // ===========【旧版本】===========
      // 分享给朋友
      wx.onMenuShareAppMessage({
        ...shareData,
        success() {
          // 用户点击了分享后执行的回调函数
          // '旧版本：分享给朋友【成功】');

          // 调用传进来的回调函数
          callBack(true, 'friend');
        },
        cancel() {
          // '旧版本：【取消】分享给朋友';

          // 调用传进来的回调函数
          callBack(false, 'friend');
        },
      });

      // 分享到朋友圈
      wx.onMenuShareTimeline({
        ...shareData,
        success() {
          // 用户点击了分享后执行的回调函数
          // '旧版本：分享到朋友圈【成功】';

          // 调用传进来的回调函数
          callBack(true, 'friendCircle');
        },
        cancel() {
          // '旧版本：【取消】分享到朋友圈';

          callBack(false, 'friendCircle');
        },
      });
    }
  };

  /**
   * 设置企微分享配置
   */
  async setQwWxShare(
    shareData: ShareDataType,
    callBack: (success: boolean, type: string) => void,
  ) {
    const { wx } = window;
    await this.getReadyCallBack()
    // 【转发】转发会发送至企微环境下 或者 外部联系人 不需要根据view做判断 因为在进入项目时 企微环境下会默认开启预览模式
    wx.onMenuShareAppMessage({
      ...shareData,
      success() {
        // 用户确认分享后执行的回调函数

        // 调用传进来的回调函数
        callBack(true, 'friend');
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        // 调用传进来的回调函数
        callBack(false, 'friend');
      },
    });
    // 发送至【微信朋友】链接
    wx.onMenuShareWechat({
      ...shareData,
      success() {
        // 用户确认分享后执行的回调函数
        callBack(true, 'friend');
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        callBack(false, 'friend');
      },
    });
    // 分享至朋友圈
    wx.onMenuShareTimeline({
      ...shareData,
      success() {
        // 用户确认分享后执行的回调函数
        callBack(true, 'friendCircle');
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        callBack(false, 'friendCircle');
      },
    });
  };

  /**
   * 设置jsSDK
   */
  async setQwJsSDK(wxConfig: WxConfigType) {
    const { wx } = window;
    const { verifyJsApiList, debug, registerJsApiList } = this.baseConfig;
    const configData = {
      beta: true,
      debug,
      ...wxConfig.corp,
      jsApiList: Array.from(new Set([
        ...qwRegisterJsApiList,
        ...(registerJsApiList || []),
      ])),
    };
    wx.config(configData);
    return this.setAgentConfig({
      wxConfig: wxConfig.agent,
      jsApiList: Array.from(new Set([
        ...qwVerifyJsApiList,
        ...(verifyJsApiList || []),
      ])),
    })
  };

  // 企微/个微调用界面分享
  async initShare(shareData: ShareDataType, config: {
    readonly callBack?: (params: any) => void,
    readonly signUrl?: string
  } = {}) {
    try {
      const handleCallBack = (success: boolean, type: string) => {
        if (typeof config.callBack === 'function') {
          config.callBack({
            success,
            type,
            shareData,
          });
        }
      };      
      // 注册config
      await this.initShareSDK(config.signUrl)
      // 需要统一对link做处理，自动添加参数_acc_, 值可以取网关响应header X-Cf-Accid中获取，作用与灰度
      if (isWx) {
        await this.setWxShare(shareData, handleCallBack);
      } else {
        await this.setQwWxShare(shareData, handleCallBack);
      }
      // 显示所有按钮
      // this.showShareMenu();
      const { hideMenuList, showMenuList } = this.baseConfig;
      // 需要显示的菜单
      this.showMenu(showMenuList || [])
      // 需要影藏的菜单
      this.hideMenu(hideMenuList || [])
      // 执行队列
      this.handlePendingList()
      // 执行后面的逻辑：分享等
    } catch (err) {
      console.log('err: ', err);
    }
  };

  // 企微/个微分享config注册
  async initShareSDK (signUrl?: string) {
    const { message, sdkUrl } = this.baseConfig;
    // 获取微信sdkjs
    try {
      await setLoadWxSdk(sdkUrl);
    } catch (error) {
      console.log('error: ', error);
      message && message('微信sdk加载失败');
      return
    }

    try {
      const wxConfig: any = await this.getJsSdkSignConfig({
        signUrl: signUrl || '',
      }) || {};
      if (!isMicromessenger) {
        await this.setAgentConfig({
          wxConfig: wxConfig?.agent
        })
      } else if (isWx) {
        await this.setWxShareConfig(wxConfig);
      } else {
        await this.setQwWxShareConfig(wxConfig);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // 初始化企微sdk
  async initQwJsSDK(signUrl?: string) {
    const { hideMenuList, showMenuList, agentId, corpId, message, sdkUrl } = this.baseConfig;

    // 获取微信sdkjs
    try {
      await setLoadWxSdk(sdkUrl);
    } catch (error) {
      message && message('微信sdk加载失败');
      return
    }
    if (!agentId || !corpId) {
      console.info('无agentId或者corpId，跳过sdk初始化')
      return
    }

    try {
      // 请求接口获取sdk配置信息
      const wxConfig: any = await this.getJsSdkSignConfig({
        signUrl: signUrl || '',
      });
      // 注册sdk方法
      await this.setQwJsSDK(wxConfig);
      // 显示所有按钮
      // this.showShareMenu();
      // 需要显示的菜单
      this.showMenu(showMenuList || [])
      // 需要影藏的菜单
      this.hideMenu(hideMenuList || [])
      // 执行队列
      this.handlePendingList()
      // 我们可以在这里取重新wx.invoke
      const { wx } = window;
      const fun = wx.invoke;
      // 需要对企微wx.invoke进行处理
      if (window.wx.openEnterpriseChat) {
        const openEnterpriseChat = window.wx.openEnterpriseChat
        window.wx.openEnterpriseChat = async (params: any) => {
          const reParams = { ...params }
          if (reParams.userIds) {
            const reUserIds = reParams.userIds.split(';')
            const userIds = await getIdmapping({
              corpid: corpId || '',
              ids: reUserIds,
              id_type: 'user',
              to_open: true
            }, this.idmapping); // wxres.userId || '';
            reParams.userIds = userIds
          }
          if (reParams.externalUserIds) {
            const reExternalUserIds = reParams.externalUserIds.split(';')
            const externalUserIds = await getIdmapping({
              corpid: corpId || '',
              ids: reExternalUserIds,
              id_type: 'external',
              to_open: true
            }, this.idmapping);
            reParams.externalUserIds = externalUserIds
          }
          openEnterpriseChat(reParams)
        }
      }
      window.wx.invoke = async (...arr: readonly any[]) => {
        // const { corpId } = this.baseConfig;
        const list = [...arr]
        // 做出逻辑处理
        const event = list[0]
        const cb = list[2]
        if (event === 'getCurExternalContact') {
          list[2] = async (res: any) => {
            const result = { ...res }
            if (res.err_msg === 'getCurExternalContact:ok') {
              result.userId = await getIdmapping({
                corpid: corpId || '',
                ids: result.userId ? [result.userId] : [],
                id_type: 'external',
                to_open: false
              }, this.idmapping);
            }
            cb && cb(result)
          } 
        }

        if (event === 'openUserProfile') {
          const params = { ...list[1] }
          params.userid = await getIdmapping({
            corpid: corpId || '',
            ids: params.userid ? [params.userid] : [],
            id_type: params.type === 1 ? 'user' : 'external',
            to_open: true
          }, this.idmapping);
          list[1] = params
        }

        if (event === 'selectEnterpriseContact') {
          // 转换请求参数
          const reqParams = { ...list[1] }
          const { selectedUserIds } = reqParams
          // 传了参数就去转换
          if (selectedUserIds) {
            reqParams.selectedUserIds = await getIdmapping({
              corpid: corpId || '',
              ids: selectedUserIds ? [selectedUserIds] : [],
              id_type: 'user',
              to_open: true
            }, this.idmapping, false);
          }
          list[1] = reqParams

          // 转换拿到的参数
          list[2] = async (res: any) => {
            //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
            if (res.err_msg === 'selectEnterpriseContact:ok') {
              if (typeof res.result === 'string') res.result = JSON.parse(res.result)
              const userIds = res.result.userList?.map((item: any) => item.id)
              const replacedIds = await getIdmapping({
                corpid: corpId || '',
                ids: userIds ? [...userIds] : [],
                id_type: 'user',
                to_open: false
              }, this.idmapping, false);
              res.result.userList.forEach((item: any, index: number) => {
                item.id = replacedIds[index]
              })
            }
            cb && cb(res)
          }
        }
        fun(...list);
      };
    } catch (error) {
      message && message('更新企微方法失败');
    }
  };

  // 初始化个微SDK
  async initWxJsSDK(signUrl?: string) {
    const { message, sdkUrl } = this.baseConfig;
    // 获取微信sdkjs
    try {
      await setLoadWxSdk(sdkUrl);
    } catch (error) {
      message && message('微信sdk加载失败');
      return
    }

    const wxConfig: any = await this.getJsSdkSignConfig({
      signUrl: signUrl || '',
    }) || {};

    if(!window.wx){
      return
    }

    const { debug, registerJsApiList, openTagList } = this.baseConfig;
    const jsApiList = Array.from(new Set([
      ...wxShareConfig,
      ...(registerJsApiList || []),
      ...(openTagList || [])
    ]))
    window.wx.config({
      debug,
      jsApiList,
      openTagList: openTagList || [],
      ...wxConfig.corp,
    });

    // 必须放在 回调函数里，不然当在注册的时候 ，有可能还没有返回数据回来，例如刷新问题
    // 微信检查接口列表
    window.wx.checkJsApi({
      jsApiList,
      // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      openTagList: openTagList || [],
      success: function(res: any) {
        // 以键值对的形式返回，可用的api值true，不可用为false
        console.log('wx.checkJsApi:success ', res);
      }
    });
    // 等待wx.ready完成
    await this.getReadyCallBack()
    const { hideMenuList, showMenuList } = this.baseConfig;
    window.wx.ready(()=>{
      // 需要显示的菜单
      window.wx.showMenuItems({
        menuList: showMenuList || []
      })
      // 需要影藏的菜单
      window.wx.hideMenuItems({
        menuList: hideMenuList || []
      })
    })
  }

  // 统一初始化方法
  async initWxConfig(signUrl?: string) {
    try {
      if(isQw){
        await this.initQwJsSDK(signUrl)
      }else if(isWx) {
        await this.initWxJsSDK(signUrl)
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  // 显示说有可屏蔽菜单
  async showShareMenu (): Promise<any> {
    const { wx } = window;
    if (!wx.ready) return this.sdkPendingList.push(() => { this.showShareMenu() })
    await this.getReadyCallBack()
    setTimeout(() => {
      wx.showMenuItems({
        menuList: [
          ...shareList,
        ]
      })
    }, 500)
  };

  // 屏蔽所有可屏蔽菜单
  async hideShareMenu (): Promise<any> {
    const { wx } = window;
    if (!wx.ready) return this.sdkPendingList.push(() => { this.hideShareMenu() })
    await this.getReadyCallBack()
    setTimeout(() => {
      wx.hideMenuItems({
        menuList: [
          ...shareList,
          ...shareCopyList
        ]
      })
    }, 500)
  };

  // 隐藏菜单内容（menuList）
  async hideMenu (menuList: readonly string[], defer = 500): Promise<any> {
    const { wx } = window;
    if (!wx.ready) return this.sdkPendingList.push(() => { this.hideMenu(menuList, defer) })
    await this.getReadyCallBack()
    setTimeout(() => {
      wx.hideMenuItems({
        menuList: [
          ...menuList
        ]
      })
    }, defer)
  };

  // 显示菜单内容
  async showMenu (menuList: readonly string[], defer = 500): Promise<any> {
    const { wx } = window;
    if (!wx.ready) return this.sdkPendingList.push(() => { this.showMenu(menuList, defer) })
    await this.getReadyCallBack()
    setTimeout(() => {
      wx.showMenuItems({
        menuList: [
          ...menuList
        ]
      })
    }, defer)
  };

  // 隐藏复制按钮
  async hideBasicMenu (): Promise<any> {
    const { wx } = window;
    if (!window.wx.ready) return this.sdkPendingList.push(() => { this.hideBasicMenu() })
    await this.getReadyCallBack()
    wx.ready(() => {
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: [
            ...shareCopyList
          ]
        })
      }, 500)
    })
  };

  // 设置sdk缓冲队列
  handlePendingList () {
    while (this.sdkPendingList.length) {
      const fun = this.sdkPendingList.shift()
      typeof fun === 'function' && fun()
    }
  };
}

const initJSSDK = (config: ConfigType) => {
  const params = getParams()
  const reConfig = {
    ...params,
    ...config,
  }
  if (isQw && (!reConfig.corpId || !reConfig.agentId)) {
    window.wxScrmJsdk = 'pass';
  }
  return CfxJsSdkInit.getInstance(reConfig)
}

export {
  initJSSDK,
  jsdkAutoLoginFunc,
  setLoadWxSdk
}

export default {
  initJSSDK,
  jsdkAutoLoginFunc,
  setLoadWxSdk
}